import Studio11 from '../../../Media/Kamar/Studio 1/Studio 1 (1).webp'
import Studio12 from '../../../Media/Kamar/Studio 1/Studio 1 (2).webp'
import Studio13 from '../../../Media/Kamar/Studio 1/Studio 1 (3).webp'
import Studio14 from '../../../Media/Kamar/Studio 1/Studio 1 (4).webp'



const Studio1images =[ 
    Studio11,Studio12,Studio13,Studio14
]

export default Studio1images;