import React from "react";
import "./lokasi.scss";
import mapsimage from "../../Media/Maps_Desktop.png";
import mobilemaps from "../../Media/Maps_Mobile.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div id="location" className="lokasi">
      <div className="judul">Lokasi</div>
      <div className="maps-lokasi">
        <img
          id="location"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
    </div>
  );
};

export default lokasi;
