import React from "react";
import "./home.scss";
import Banner from "../Section/Banner/banner.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Rumah from "../Section/Rumah/rumah.jsx";
import Fasilitas from "../Section/Surrounding Area/surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import tombol from "../Media/tombol.webp";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285283066969&text=Halo%20Kak,%20Saya%20lihat%20iklan%20diwebsite,%20apa%20ada%20room%20available?%20https://sewa-apartemen-lagoon.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Banner />
      <Promo />
      <Rumah />
      <Fasilitas />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
