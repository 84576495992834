import Surrounding1 from '../../../src/Media/Fasilitas/Fasilitas (1).webp'
import Surrounding2 from '../../../src/Media/Fasilitas/Fasilitas (2).webp'
import Surrounding3 from '../../../src/Media/Fasilitas/Fasilitas (3).webp'
import Surrounding4 from '../../../src/Media/Fasilitas/Fasilitas (4).webp'







const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4
]

export default Surroundingimages;