import Studio21 from '../../../Media/Kamar/Studio 2/Studio 2 (1).webp'
import Studio22 from '../../../Media/Kamar/Studio 2/Studio 2 (2).webp'
import Studio23 from '../../../Media/Kamar/Studio 2/Studio 2 (3).webp'
import Studio24 from '../../../Media/Kamar/Studio 2/Studio 2 (4).webp'
import Studio25 from '../../../Media/Kamar/Studio 2/Studio 2 (5).webp'


const Studio2images =[
Studio21,Studio22,Studio23,Studio24,Studio25
]

export default Studio2images;