import Studio41 from '../../../Media/Kamar/Studio 4/Studio 4 (1).webp'
import Studio42 from '../../../Media/Kamar/Studio 4/Studio 4 (2).webp'
import Studio43 from '../../../Media/Kamar/Studio 4/Studio 4 (3).webp'
import Studio44 from '../../../Media/Kamar/Studio 4/Studio 4 (4).webp'
import Studio45 from '../../../Media/Kamar/Studio 4/Studio 4 (5).webp'


const Studio4images =[
Studio41,Studio42,Studio43,Studio44,Studio45
]

export default Studio4images;