import Studio31 from '../../../Media/Kamar/Studio 3/Studio 3 (1).webp'
import Studio32 from '../../../Media/Kamar/Studio 3/Studio 3 (2).webp'
import Studio33 from '../../../Media/Kamar/Studio 3/Studio 3 (3).webp'
import Studio34 from '../../../Media/Kamar/Studio 3/Studio 3 (4).webp'




const Studio3images =[
Studio31,Studio32,Studio33,Studio34
]

export default Studio3images;