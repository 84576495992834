import Bed1 from '../../../Media/Kamar/1BR/1BR (1).webp'
import Bed2 from '../../../Media/Kamar/1BR/1BR (2).webp'
import Bed3 from '../../../Media/Kamar/1BR/1BR (3).webp'
import Bed4 from '../../../Media/Kamar/1BR/1BR (4).webp'
import Bed5 from '../../../Media/Kamar/1BR/1BR (5).webp'



const Bedimages =[
Bed1,Bed2,Bed3,Bed4,Bed5
]

export default Bedimages;